import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import KEEPLogo from "/content/assets/logo.png"
import AmplifyCareer from "/content/assets/resources/amplify-career-tour.jpg"
import Gateway from "/content/assets/resources/gateway-education-program.jpg"

const Education = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Education</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="education-hero">
            <div class="hero-body">
            </div>
      </section>      
      <main>
        <h1>Educational Resources</h1>
        <p>The KEEP Foundation is committed to investing in our youth by providing opportunities for them to be introduced to horses, grow and thrive as a member of the equine community, and become the next generation to lead our industry.</p>

        <div class="featured-content">
          <span class="featured-logo"><img src={KEEPLogo} alt="KEEP" /></span>
          <h3>Gateway Education Program</h3>
          <h4>"Opening the gate to students for hands on career opportunities in the horse industry"</h4>
          <span class="featured-photo"><img src={Gateway} alt="Gateway" /></span>
          <span class="featured-content">
            <p>This job shadow program allows High School Seniors to observe professionals in the equine industry and experience a day in the life of their operation, gain real life understanding of careers, career pathways, and the education and skills needed for the job.</p>
          </span>
        </div>

        <div class="featured-content">
          <span class="featured-logo"><img src={AmplifyCareer} alt="Amplify Junior" /></span>
          <h3>Equine Careers Tour Series</h3>
          <h4>in partnership with Amplify Horse Racing</h4>
          <span class="featured-content">
            <p>Educational tour series for youth and young adults ages 16-25 that takes place monthly from March-October. Each destination features a particular aspect of the equine industry and the jobs and careers that support it.</p>
            <p>For more information about Amplify Horse Racing, visit <a href="https://www.amplifyhorseracing.org/" rel="noreferrer" target="_blank">https://www.amplifyhorseracing.org/</a>.</p>
          </span>
        </div>
      </main>
    </Layout>
  )
}

export default Education

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`